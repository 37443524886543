import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="List Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/list/"
    metaDescription="Use Uizard's ready-to-use list templates! Uizard has 10+ list templates including to-do lists, article entries, settings lists, and so much more!"
    description="
    h2:The most common lists ready to customize
    <br/>
    Drag-and-drop pre-made list templates and customize them to fit any needs or styles.
    <br/>
    Use Uizard's ready-to-use list templates to easily create apps and websites for any purpose! Uizard has 10+ list templates including to-do lists, article entries, settings lists, and so much more!
    <br/>
    h3:Create the perfect organized list that fits your content
    <br/>
    Pick from a variety of list templates to include the list that perfectly fits your content. All the lists on your app or website will look interesting and will always match your chosen theme.
    <br/>
    h3:Don't be daunted by a blank document
    <br/>
    When you use Uizard, there's no need to feel daunted by blank documents. List templates, part of a wider array of component template, can easily be used through drag-and-drop. The lists are self-explanatory and extremely easy to edit and customize for your particular use case.
    <br/>
    All lists can be appropriated with your own text, images, and colors. It will only take a few minutes for you to come up with an engaging mockup featuring the lists you need.
    "
    pages={[
      "Settings list",
      "Items list with visuals",
      "Comment reviews",
      "Video list",
      "Conversation list and stories",
      "Song list",
      "Cart overview",
      "Contact list",
      "Call to action list",
      "Article entries",
      "Email inbox list",
      "Settings menu",
      "Simple list of items",
      "To-do list",
      "Audio list",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing lists"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing lists"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing lists"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing lists"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing lists"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/component-templates/header/header_1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/component-templates/header/header_2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/component-templates/header/header_3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/component-templates/header/header_4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/component-templates/header/header_5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
