import * as React from "react";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import { Link } from "gatsby-plugin-react-i18next";
import Styled from "styled-components/macro";
import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import Content from "../../../components/layout/Content/Content";
import Headline from "../../../components/texts/Headline";
import Description from "../../../components/texts/Description";
import MoreTemplatesSection from "../../../components/pages/templates/MoreTemplatesSection";

const ContentExtended = Styled(Content)`
    margin-bottom: 10rem;
    
    ${Media.greaterThan("900px")`
        margin-bottom: 12rem;
    `}
`;

const Columns = Styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    
    ${Media.greaterThan("900px")`
        flex-direction: row;
    `}
`;

const ColumnText = Styled.div`
  width: 100%;
    
  ${Media.greaterThan("900px")`
    width: ${(props) => props.widthPercentage}%;
  `}
`;

const HeadlineExtended = Styled(Headline)`
  margin-top: 1rem;
  font-size: 3.5rem;

  ${Media.lessThan("medium")`
    font-size: 2.5rem;
  `}
`;

const ContentH2 = Styled.h2`
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: 600;
  color: inherit;
  letter-spacing: -0.3px;
  margin: 0 0 1.5rem 0;

  ${Media.greaterThan("large")`
    font-size: 3.5rem;
    line-height: 4rem;
    margin: 0 0 3rem 0;
  `}
`;

const ContentLink = Styled.a`
  font-weight: bold;
`;

const ContentH3 = Styled.h3`
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 600;
  color: inherit;
  letter-spacing: -0.3px;
  margin: 0 0 1.5rem 0;

  ${Media.greaterThan("large")`
    font-size: 2.2rem;
    line-height: 3rem;
    margin: 0 0 3rem 0;
  `}
`;

const ContentH4 = Styled.h4`
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: 600;
  color: inherit;
  letter-spacing: -0.3px;
  margin: 0 0 1.5rem 0;

  ${Media.greaterThan("large")`
    font-size: 3.5rem;
    line-height: 4rem;
    margin: 0 0 3rem 0;
  `}
`;

const DescriptionExtended = Styled(Description)`
  font-size: 2rem;
  margin: 3rem 0;
`;

const PageListItem = Styled.li`
  font-size: 2rem;
`;

const BreadcrumbContainer = Styled.div`
  margin-top: 10rem;

  ${Media.greaterThan("medium")`
    margin-top: 16rem;
  `}
`;

const BreadcrumbLink = Styled(Link)`
  font-size: 1.5rem;

  &:hover {
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
  }
`;

const Breadcrumb = Styled.span`
  font-size: 1.5rem;
`;

const Chevron = Styled.span`
  position: relative;
  margin: 0 0.5rem;
  top: 0.35rem;
`;

const ImagePrimary = Styled(Image)`
  display: block;
  border-radius: 0.8rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.11);
  margin-bottom: 3rem;
`;

const ImageSecondary = Styled(Image)`
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.11);
`;

const Thumbnails = Styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 3rem 0;
`;

type TemplatePageProps = {
  title: string;
  category: string;
  url: string;
  metaDescription: string;
  description: string;
  pages: Array<String>;
  img1: GatsbyImageProps;
  img1alt: string;
  img2: GatsbyImageProps;
  img2alt: string;
  img3: GatsbyImageProps;
  img3alt: string;
  img4: GatsbyImageProps;
  img4alt: string;
  img5: GatsbyImageProps;
  img5alt: string;
};

export default ({
  title,
  category,
  url,
  metaDescription,
  description,
  pages,
  img1,
  img1alt,
  img2,
  img2alt,
  img3,
  img3alt,
  img4,
  img4alt,
  img5,
  img5alt,
}: TemplatePageProps) => (
  <Layout>
    <Meta title={title} description={metaDescription} url={url} />

    <ContentExtended narrow>
      <BreadcrumbContainer>
        <BreadcrumbLink to="/templates/">Templates</BreadcrumbLink>
        <Chevron aria-hidden="true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path d="M6.47 4.29l3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path>
          </svg>
        </Chevron>
        <BreadcrumbLink
          to={"/templates/" + category.toLowerCase().split(" ").join("-") + "/"}
        >
          {category}
        </BreadcrumbLink>
        <Chevron aria-hidden="true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path d="M6.47 4.29l3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path>
          </svg>
        </Chevron>
        <Breadcrumb>
          {title.substring(
            0,
            title.indexOf("|") != -1 ? title.indexOf("|") - 1 : title.length
          )}
        </Breadcrumb>
      </BreadcrumbContainer>
      <Columns>
        <ColumnText widthPercentage="75">
          <HeadlineExtended as="h1">
            {title.substring(
              0,
              title.indexOf("|") != -1 ? title.indexOf("|") - 1 : title.length
            )}
          </HeadlineExtended>

          <ImagePrimary
            fluid={"fluid" in img1 ? img1.fluid : null}
            alt={img1alt}
          />

          {description.split("<br/>").map((line) => {
            if (line.indexOf("h3:") != -1) {
              return <ContentH3>{line.substring(4)}</ContentH3>;
            }
            if (line.indexOf("<a:") != -1) {
              const href = line.substring(
                line.indexOf("<a:") + 3,
                line.indexOf(">")
              );
              const text = line.substring(
                line.indexOf(">") + 1,
                line.indexOf("</a>")
              );
              return (
                <DescriptionExtended>
                  {line.substring(0, line.indexOf("<a:"))}
                  <ContentLink href={href}>{text}</ContentLink>
                  {line.substring(line.indexOf("</a>") + 4)}
                </DescriptionExtended>
              );
            }
            if (line.indexOf("h2:") != -1) {
              return <ContentH2>{line.substring(4)}</ContentH2>;
            }
            return <DescriptionExtended>{line}</DescriptionExtended>;
          })}

          <ContentH2>What components are included?</ContentH2>
          <ul>
            {pages.map((page) => {
              return <PageListItem>{page}</PageListItem>;
            })}
          </ul>

          <ContentH4>Examples</ContentH4>
          <Thumbnails>
            <ImageSecondary
              fluid={"fluid" in img2 ? img2.fluid : null}
              alt={img2alt}
            />
            <ImageSecondary
              fluid={"fluid" in img3 ? img3.fluid : null}
              alt={img3alt}
            />
            <ImageSecondary
              fluid={"fluid" in img4 ? img4.fluid : null}
              alt={img4alt}
            />
            <ImageSecondary
              fluid={"fluid" in img5 ? img5.fluid : null}
              alt={img5alt}
            />
            <ImageSecondary
              fluid={"fluid" in img1 ? img1.fluid : null}
              alt={img1alt}
            />
          </Thumbnails>
        </ColumnText>
        <MoreTemplatesSection />
      </Columns>
    </ContentExtended>
  </Layout>
);
